<template>
  <div class="box">
    <div class="lunbo">
      <el-carousel :interval="3000" arrow="always" height="304px" indicator-position="none">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <div class="title">{{item.journalisTitle}}</div>
          <el-image class="img" :src="echoImg(item.bannerUrl)" fit="cover" @click="goDetails('',item.id)"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <ul class="news1">
      <template v-for="(dates,index) in subjectList[0].journalism" @key="index">
        <li v-if="index<3" :key="index">
          <div class="title" @click="goDetails('', dates.id)">{{dates.journalisTitle}}</div>
          <div class="desc">
            <span>{{dates.message}}</span>
            <span class="time">{{toDate(dates.releaseTime,"yyyy-MM-dd")}}</span>
          </div>
        </li>
      </template>
    </ul>
    <template v-for="(item,index) in subjectList" @key="index">
      <ul class="notice" v-if="index>0">
        <div class="titleBox" :key="index">
          <span class="title">{{item.navigationName}}</span>
          <span class="more" @click="more(item.navigationName,item.id,item.navigationType)">更多>></span>
        </div>
        <template v-for="(dates,idxs) in item.journalism" @key="idxs">
          <li v-if="idxs<9" :key="idxs">
            <span class="noticeTitle" @click="goDetails(dates.navigationName,dates.id)"><span class="theme">{{dates.journalisTitle}}</span></span>
            <span class="noticeTime">{{toDate(dates.releaseTime,"yyyy-MM-dd")}}</span>
          </li>
        </template>
      </ul>
    </template>
    <ul class="partners">
      <div class="titleBox ">
        <span class="title">精彩图片</span>
      </div>
      <div class="containt">
        <ul :style="{ left: calleft1 + 'px', width: widthData1 + 'px' }" v-on:mouseover="stopMove1()" v-on:mouseout="move1()" class="imgBoxoul">
          <li v-for="(item, index) in mapDepotList" :key="index" ref="lis">
            <el-image class="img" :src="echoImg(item.bannerUrl)" @click="goDetails('', item.id)" fit="fit"></el-image>
          </li>
          <li v-for="(dates, idx) in mapDepotList" :key="'k_' + idx" ref="lis1">
            <el-image class="img" :src="echoImg(dates.bannerUrl)" @click="goDetails('', dates.id)" fit="fit"></el-image>
          </li>
        </ul>
      </div>
    </ul>
    <ul class="partners">
      <div class="titleBox ">
        <span class="title">合作伙伴</span>
      </div>
      <div class="containt">
        <ul :style="{ left: calleft + 'px', width: widthData + 'px' }" v-on:mouseover="stopMove()" v-on:mouseout="move()" class="imgBoxoul">
          <li v-for="(item, index) in linksList2" :key="index" ref="lis">
            <el-image class="img" :src="echoImg(item.imgUrl)" @click="openLinks(item.linkUrl)" fit="fit"></el-image>
          </li>
          <li v-for="(dates, idx) in linksList2" :key="'c_' + idx" ref="lis1">
            <el-image class="img" :src="echoImg(dates.imgUrl)" @click="openLinks(dates.linkUrl)" fit="fit"></el-image>
          </li>
        </ul>
      </div>
    </ul>
  </div>
</template>
<script>
import { selectLink,selectMapDepot,selectBanner,selectList,getNavigationTwoList,getTextarea } from "@/api/template";
// import AboutUs from "./aboutUs";
import { mapState } from "vuex";
export default {
  name: "Index",
  // components: { AboutUs },
  data() {
    return {
      linksList: [],
      mapDepotList: [],
      linksList1:[],
      linksList2:[],
      bannerList: [],
      subjectList: [],
      synopsis:'',
      introduce:'',
      calleft: 0,
      speed: 1,
      timer: '',

      calleft1: 0,
      speed1: 1,
      timer1: '',
      lyList:['../images/gg1.jpg','../images/gg2.jpg'],
      subjectList1:{},
      eventSeriesList:[],
      noticeList:[],
      memberList:[],
      comprehensiveList:[],
      hotEventsList:[],
      newsList:[],
      businessList:[],
      picturesList:[]
    };
  },
  created(){
    this.init()
    this.move()
    this.move1()
  },
  computed: {
    widthData(){
      return 180 * Number(this.linksList2.length * 2)
    },
    widthData1(){
      return 180 * Number(this.mapDepotList.length * 2)
    },
    ...mapState(["color"]),
  },
  mounted() {
    // let imgBox = document.getElementsByClassName("imgBoxoul")[0];
    // imgBox.innerHTML += imgBox.innerHTML;
  },
  methods: {
    init(){
      this.getSelectBanner()
      this.getSelectList()
      this.getSelectMapDepot()
      this.getSelectLink()
      // this.getNavigationTwoList()
    },
    getNavigationTwoList(){
      let self = this
      getNavigationTwoList().then(res =>{
        if(res.code == 200){
          res.data.forEach(dates =>{
            dates.witemVo.forEach(item =>{
              item.pid = dates.id
              item.pnavigationName = dates.navigationName
              item.pnavigationType = dates.navigationType
              // if(dates.navigationName == '赛事信息系统'){
              //   this.eventSeriesList.push(item)
              // }
              // if(dates.navigationName == '会员信息系统'){
              //   this.memberList.push(item)
              // }
              // if(dates.navigationName == '综合'){
              //   this.comprehensiveList.push(item)
              // }
            })
          })
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectLink(){
      let self = this
      selectLink().then(res =>{
        if(res.code == 200){
          res.data.forEach(item =>{
            if(!item.imgUrl){
              this.linksList1.push(item)
            }else if(item.imgUrl){
              this.linksList2.push(item)
            }
          })
          this.linksList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectBanner(){
      let self = this
      selectBanner().then(res =>{
        if(res.code == 200){
          this.bannerList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectMapDepot(){
      let self = this
      selectMapDepot().then(res =>{
        if(res.code == 200){
          this.mapDepotList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectList(){
      let self = this
      selectList().then(res =>{
        if(res.code == 200){
          this.subjectList = res.data
          // console.log(this.subjectList,111)
          // res.data.forEach(item=>{
          //   if(item.navigationName=='新闻资讯'){
          //     this.subjectList1 = item
          //   }
          //   if(item.navigationName == '通知公告'){
          //     this.noticeList.push(item)
          //   }
          //   if(item.navigationName == '热点赛事'){
          //     this.hotEventsList.push(item)
          //   }
          //   if(item.navigationName == '新闻资讯'){
          //     this.newsList.push(item)
          //   }
          //   if(item.navigationName == '商务合作'){
          //     this.businessList.push(item)
          //   }
          //   if(item.navigationName == '精彩图片'){
          //     this.picturesList.push(item)
          //   }
          // })
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    openLinks(linkUrl){
      window.open(linkUrl,'_blank')
    },
    more(navigationName,id,type){
      this.$router.push({
        params: {
          name: navigationName,
          id: id,
          type: type,
          isP: 'true'
        },
        name:'seventh-list',
      })
    },
    goDetails(navigationName,id) {
      let data = {
        name: navigationName,
        id: id,
      }
      window.sessionStorage.setItem('detailsValue', JSON.stringify(data))
      this.$router.push('/seventh-details')
    },
    getTextareaFun(journalisContent,type){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        if(type == 'synopsis'){
          this.synopsis = res.data.dataHtml
        }
        if(type == 'introduce'){
          this.introduce = res.data.dataHtml
        }
      })
    },
    move() {
      this.timer = setInterval(this.starMove, 20);
    },
    move1() {
      this.timer1 = setInterval(this.starMove1, 20);
    },
    //开始移动
    starMove() {
      this.calleft -= 1.2;//*this.speed
      if (this.calleft <= -this.linksList2.length*180) {
        this.calleft = 0;
      }
    },
    starMove1() {
      this.calleft1 -= 1.2;//*this.speed
      if (this.calleft1 <= -this.mapDepotList.length*180) {
        this.calleft1 = 0;
      }
    },
    //鼠标悬停时停止移动
    stopMove() {
      clearInterval(this.timer);
    },
    stopMove1() {
      clearInterval(this.timer1);
    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  .lunbo{
    position: relative;
    width: 553px;
    height: 304px;
    display: inline-block;
    .title{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: rgba(0,0,0,0.6);
      z-index: 99;
      color: #fff;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      font-weight: bold;
    }
    .img{
      width: 100%;
    }
    /deep/ .el-carousel__arrow{
      background-color:rgba(31,45,61,.4);
    }
    /deep/.el-carousel__indicators{
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .news1{
    width: 416px;
    height: 304px;
    margin-left: 30px;
    li{
      padding: 12px 0;
      line-height: 30px;
      color: #666;
      border-bottom: 1px solid #b6b6b6;
      .title{
        font-size: 18px;
        font-weight: bold;
        color: #000;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
      }
      .desc{
        position: relative;
        .time{
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    li:last-child{
      border-bottom: none;
    }
  }
  ul{
    display: inline-block;
    vertical-align: top;
  }
  .imgDiv{
    margin: 15px 0;
    width: 100%;
  }
  .eventSeries{
    display: inline-block;
    width: 220px;
    li{
      height: 40px;
      line-height: 40px;
      padding-left: 25%;
      color: #fff;
      cursor: pointer;
      margin-bottom: 20px;
    }
    .li_0{
      background: url("../images/seventh-img01.png") 15px center no-repeat #b7627c;
    }
    .li_1{
      background: url("../images/seventh-img02.png") 15px center no-repeat #9d6bac;
    }
    .li_2{
      background: url("../images/seventh-img03.png") 15px center no-repeat #4f93dd;
    }
    .li_3{
      background: url("../images/seventh-img04.png") 15px center no-repeat #54afef;
    }
    .li_4{
      background: url("../images/seventh-img05.png") 15px center no-repeat #70c9ac;
    }
    .li_5{
      background: url("../images/seventh-img06.png") 15px center no-repeat #a8d368;
      margin-bottom: 0px;
    }
  }
  .notice{
    float: left;
    width: 485px;
    height: 330px;
    overflow: hidden;
    margin-top: 20px;
    //padding: 0 15px;
    //margin: 0 15px;
    li{
      height: 32px;
      line-height: 32px;
      padding: 0 15px;
      .noticeTitle{
        display: inline-block;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }
      .noticeTime{
        float: right;
      }
    }
  }
  .notice:nth-child(even){
    margin-left: 30px;
  }
  .memberBox{
    display: inline-block;
    width: 220px;
    .member{
      width: 100%;
      height: 100px;
      color: #fff;
      text-align: center;
      background: url('../images/seventh_vip.jpg') left top no-repeat #5284c6;
      .title{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
        border-bottom: 1px solid #a3bde1;
      }
      li{
        float: left;
        width: 33.33%;
        line-height: 22px;
        font-size: 12px;
        cursor: pointer;
      }
      .line:before {
        float: right;
        content: '|';
        display: inline-block;
        *display: inline;
        zoom: 1;
      }
    }
    .comprehensive{
      li{
        display: inline-block;
        width: 100px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 15px;
        margin-top: 20px;
        cursor: pointer;
      }
      .li_0{
        background: #ca6161;
      }
      .li_1{
        background: #b7627c;
      }
      .li_2{
        background: #9d6bac;
      }
      .li_3{
        background: #7a8ce1;
      }
      .li_4{
        background: #679eda;
      }
      .li_5{
        background: #65aed4;
      }
      .li_6{
        background: #70c9ac;
      }
      .li_7{
        background: #a8d368;
      }
      li:nth-child(even){
        margin-left: 20px;
      }
    }
  }
  .hotEvents{
    width: 220px;
    background: #5284c6;
    .titleBox{
      text-align: center;
    }
    li{
      position: relative;
      line-height: 34px;
      padding: 5px 12px 0;
      border-bottom: 1px solid #769ed2;
      font-size: 12px;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .noticeDesc{
        position: absolute;
        right: 60px;
      }
      .noticeTime{
        position: absolute;
        right: 12px;
      }
    }
  }
  .news{
    li{
      border-bottom: 1px solid #e1e1e1;
    }
  }
  .business{
    width: 220px;
    .img{
      width: 220px;
      height: 115px;
      cursor: pointer;
    }
    .noticeTitle{
      display: block;
      width: 100%;
      font-size: 14px;
      text-align: center;
      padding: 11px 0;
      cursor: pointer;
    }
  }
  .pictures{
    width: 100%;
    li{
      display: inline-block;
      width: 235px;
      height: 187px;
      margin-right: 20px;
      .img{
        width: 100%;
        margin: 15px 0;
      }
      .noticeTitle{
        display: block;
        width: 100%;
        height: 20px;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    li:last-child{
      margin-right: 0;
    }
  }
  .partners{
    width: 100%;
    margin-top: 20px;
    .bTitle{
      height: 36px;
      line-height: 36px;
      background: #f2f2f2;
      font-size: 16px;
      font-weight: bold;
      color: #e60012;
      padding-left: 10px;
    }
    .containt {
      position: relative;
      //padding: 20px 0;
      overflow-y: auto;
      width: 100%;
      height: 168px;
      overflow: hidden;
      margin: 0 auto;
      ul {
        margin: 0 auto;
        width: 100%;
        position: absolute;
        left: 0px;
        cursor: pointer;
        z-index: 10;
        overflow: hidden;
        li {
          float: left;
          height: 120px;
          padding: 30px 30px 0;
          overflow: hidden;
          text-align: center;
          .img{
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
  .titleBox{
    width: 100%;
    height: 40px;
    line-height: 40px;
    position: relative;
    color: #fff;
    background: url("../images/seventh_gonggao.jpg")  right center no-repeat #5284c6;
    .title{
      font-size: 18px;
      font-weight: bold;
      padding: 0 15px;
    }
    .more{
      position: absolute;
      right: 15px;
      bottom: 0;
      cursor: pointer;
    }
  }
  .white{
    cursor: pointer;
  }
  .white:hover{
    border-bottom: 1px solid #fff;
  }
  .theme:hover{
    border-bottom: 1px solid #a3bdff;
    color: #a3bdff;
  }
}

</style>
